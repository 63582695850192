import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SearchSite from '../searchSite/searchSite';
import RequestCallback from '../requestCallBack/requestCallback';
import useDefaultMenus from '../../hooks/useDefaultMenus';
import { ImPhoneHangUp } from '@react-icons/all-files/im/ImPhoneHangUp';
import { BsSearch } from '@react-icons/all-files/bs/BsSearch';
import { VscChromeClose } from '@react-icons/all-files/vsc/VscChromeClose';
import * as Styled from './defaultHeaderMenuStyle';
import constants from '../../utils/utils';
import iconAccount from '../../images/icon-account.png';
import isExternalUrl, { isExternalUrlhref,addTrailingSlash } from '../../utils';

const DefaultHeaderMenu = ({
  showRequestCallBackProp,
  setShowRequestCallBackProp,
}) => {
  const menu = useDefaultMenus();

  const [modalShow, setModalShow] = React.useState(false);
  const [showSearchSite, setShowSearch] = React.useState(false);
  const [showRequestCallBack, setShowRequestCallBack] = React.useState(false);

  const handleModalShow = (isShow) => {
    setModalShow(isShow);
  };

  const requestCallBackClick = () => {
    handleModalShow(false);
    setShowSearch(false);
    setShowRequestCallBack(
      showRequestCallBackProp ? false : !showRequestCallBack
    );
    if (setShowRequestCallBackProp) {
      setShowRequestCallBackProp(false);
    }
  };

  const clientSpaceClick = () => {
    handleModalShow(true);
    setShowSearch(false);
    setShowRequestCallBack(false);
    if (setShowRequestCallBackProp) {
      setShowRequestCallBackProp(false);
    }
  };
  const searchSiteClick = () => {
    handleModalShow(false);
    setShowSearch(!showSearchSite);
    setShowRequestCallBack(false);
    if (setShowRequestCallBackProp) {
      setShowRequestCallBackProp(false);
    }
  };

  return (
    <>
      <Styled.DefaultHeaderContainer>
        <Container fluid className="d-none d-md-block">
          <Row>
            <Col lg className="d-none d-lg-block" />
            <Styled.InnerDefaultHeaderContainer xs="auto">
              <Row className="g-0 justify-content-md-center justify-content-lg-end">
                {menu.listOfImagesWithPath.map((item, index) => {
                  return (
                    <Col xs="auto" key={index}>
                      {item.title ===
                        constants.DEFAULT_MENU.REQUEST_A_CALLBACK && (
                        <Styled.TransparentButton
                          onClick={() => requestCallBackClick()}
                          className={showRequestCallBack && 'active'}
                        >
                          <span>{item.title}</span>
                          {showRequestCallBack || showRequestCallBackProp ? (
                            <VscChromeClose
                              color="#E4B01F"
                              size="25px"
                              className="icon-margin-right"
                            />
                          ) : (
                            <ImPhoneHangUp
                              style={{
                                color: 'transparent',
                                stroke: '#E4B01F',
                                strokeWidth: '1px',
                              }}
                              className="icon-margin-right"
                              size="20px"
                            />
                          )}
                        </Styled.TransparentButton>
                      )}

                      {item.title === constants.DEFAULT_MENU.SEARCH_SITE && (
                        <Styled.TransparentButton
                          onClick={() => searchSiteClick()}
                          className={showSearchSite && 'active'}
                        >
                          <span>{item.title}</span>
                          {showSearchSite ? (
                            <VscChromeClose
                              color="#E4B01F"
                              size="25px"
                              className="icon-margin-right"
                            />
                          ) : (
                            <BsSearch
                              color="#E4B01F"
                              size="16px"
                              className="icon-margin-right"
                            />
                          )}
                        </Styled.TransparentButton>
                      )}

                      {item.title ===
                        constants.DEFAULT_MENU.CLIENT_SPACE.DEFAULT && (
                        <Styled.BlueButton
                          onClick={() => clientSpaceClick()}
                          href={isExternalUrlhref(item.path)? item.path : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + item.path)}
                          target={isExternalUrl(item.path) ? '_blank' : '_self'}
                        >
                          {item.title}
                          <Styled.IconStyle src={iconAccount} />
                        </Styled.BlueButton>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Styled.InnerDefaultHeaderContainer>
            <Col lg className="d-none d-lg-block" />
          </Row>
        </Container>
        {showSearchSite && (
          <Styled.SearchContainer fluid>
            <SearchSite />
          </Styled.SearchContainer>
        )}
        {(showRequestCallBack || showRequestCallBackProp) && (
          <div className="d-none d-md-block">
            <RequestCallback />
          </div>
        )}
      </Styled.DefaultHeaderContainer>
    </>
  );
};
export default DefaultHeaderMenu;
