import { graphql, navigate } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../styles/globalStyles';
import HomePage from '../templates/homePageTemplate/homePageTemplate';

const RootIndex = ({ location }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
    if (location?.pathname !== '/') {
      navigate('/404');
    }
  }, []);

  return !loaded ? (
    <>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Heating Oil &amp; Propane Supplier for Home &amp; Commercial | Island
          Petroleum
        </title>
        <meta name="description" content></meta>
      </Helmet>
      <HomePage />
    </>
  ) : (
    <>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Heating Oil &amp; Propane Supplier for Home &amp; Commercial | Island
          Petroleum
        </title>
      </Helmet>
      <HomePage />
    </>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  fragment PageContent on ContentfulContent {
    id
    title
    type
    header
    subText
    elementId
    contentDetails {
      raw
    }
  }
  fragment ContentList on ContentfulContentList {
    id
    title
    type
    header
    subtext
    elementId
    list {
      title
      type
      header
      subText
      elementId
      contentDetails {
        raw
      }
    }
    listDetails {
      raw
    }
  }
  fragment ContentFaqs on ContentfulFaqs {
    id
    title
    type
    description
    qna {
      title
      text {
        raw
      }
    }
  }
  fragment ContentfulListImages on ContentfulListOfImageWithPath {
    id
    type
    title
    listOfImagesWithPath {
      title
      type
      richText {
        raw
      }
      image {
        file {
          url
        }
      }
    }
  }
  fragment TextWithImage on ContentfulTextWithImage {
    id
    title
    type
    image {
      title
      file {
        url
      }
    }
    text {
      text
    }
  }
  fragment FAQContent on ContentfulFaqs {
    id
    title
    type
    header
    description
    elementId
    qna {
      title
      type
      text {
        raw
      }
      buttons {
        raw
      }
    }
  }
  fragment MultimediaText on ContentfulMultimediaText {
    title
    type
    multimediaText: text {
      raw
    }
  }
  fragment ImagesWithPathList on ContentfulListOfImageWithPath {
    id
    title
    type
    listOfImagesWithPath {
      title
      type
      path
      richText {
        raw
      }
      image {
        file {
          fileName
          url
        }
      }
    }
  }
  fragment ImageWithPath on ContentfulImageWithPath {
    id
    title
    type
    path
    richText {
      raw
    }
    image {
      file {
        url
      }
    }
  }
  fragment ImageContainer on ContentfulImageContainer {
    id
    title
    type
    image {
      file {
        url
      }
    }
    assets {
      title
      description
      file {
        fileName
        url
      }
    }
    textWithPaths {
      ... on ContentfulTextWithPath {
        id
        path
        text
      }
    }
    headerWithText {
      headerText
      text {
        text
      }
    }
  }
`;
